<template>
  <OneColumn style="background: #19173B;">
    <template v-slot:ColumnContent>
      <div class="ContentWrapper">
        <div class="Content">
          <!-- <div class="ButtonBack" @click="this.$router.go(-1)">
            <span class="centerText">
              <span class="ArrowBack">&larr;</span>
              <span>Назад</span>
            </span>
          </div> -->

          <span class="Page-Achievements-Header">Достижения</span>

          <div class="ShortInfo">
            <div class="MyAchievements">
              <div class="Head">
                <span class="HeadText">Мои достижения</span>
                <!-- <CircleQuestionIcon /> -->
              </div>

              <div class="MyAchList">
                <el-tooltip
                  class="box-item"
                  effect="customized"
                  :content="`Получено достижение &laquo;${ach.name}&raquo;`"
                  placement="top-start"
                  v-for="(ach, aindx) in achievementsList"
                  :key="`${aindx}${ach.id}`"
                >
                  <div class="AchBlock" :style="getAchIMG(ach.url_img)"></div>
                </el-tooltip>

                <el-tooltip
                  class="box-item"
                  effect="customized"
                  :content="`Пусто`"
                  placement="top-start"
                  v-for="n in 7 - getNullBlocksCounter"
                  :key="`${n}`"
                >
                  <div class="AchBlock" :key="`${n}`"></div>
                </el-tooltip>
              </div>
            </div>

            <!-- <div class="MyXP">
              <div class="Head">
                <span class="HeadText">Мой опыт</span>
                <CircleQuestionIcon />
              </div>

              <span class="GradientText">250 из 1000</span>
            </div> -->

            <div class="MyBalance">
              <div class="Head">
                <span class="HeadText">Мой баланс</span>
                <!-- <CircleQuestionIcon /> -->
              </div>

              <span class="GradientText"
                >{{ getFormattedCoinsValue }} окто<span
                  style="font-weight: 400;"
                  >{{ getCoinsText }}</span
                ></span
              >
            </div>
          </div>

          <div class="HistoryList">
            <span class="HeadText">История событий</span>
            <div class="HLContent">
              <div
                class="HLBlock"
                v-for="(ach, aindx) in getHistoryList"
                :key="`${aindx}${ach.element_type}${ach.id}`"
              >
                <!-- Ачивки -->
                <template v-if="ach.element_type === 'ach'">
                  <div class="XPLineWrapper" style="min-width: 65px;">
                    <div class="AchIMG" :style="getAchIMG(ach.url_img)"></div>
                  </div>

                  <div class="Text">
                    <span class="HBlockText"
                      >Получено достижение &laquo;{{ ach.name }}&raquo;
                    </span>
                    <div class="BottomText">
                      <span class="AchText" lang="ru">{{
                        ach.description
                      }}</span>
                      <span class="AchDate" lang="ru"
                        >Получено {{ getRightDate(ach.createdDate) }} в
                        {{ getRightTime(ach.createdDate) }}</span
                      >
                    </div>
                  </div>
                </template>

                <!-- Коины -->
                <template v-if="ach.element_type === 'history'">
                  <div class="XPLineWrapper" style="min-width: 65px;">
                    <Octocoins
                      style="margin: auto; "
                      :IconHexColor="'#6FCF97'"
                      :BorderColor="'#6FCF97'"
                    >
                      <template v-slot:content>
                        <span class="OctocoinsText"
                          >{{ ach.value_type == "Получение" ? "+" : "-"
                          }}{{ getFormattedNumber(ach.coins_value) }}</span
                        >
                      </template>
                    </Octocoins>
                  </div>
                  <div class="Text">
                    <span class="HBlockText"
                      >{{
                        ach.value_type == "Получение"
                          ? "Начисление"
                          : "Списание"
                      }}
                      октокоинов</span
                    >
                    <div class="BottomText">
                      <span class="AchText" lang="ru">{{ ach.reason }}</span>
                      <span class="AchDate" lang="ru"
                        >Получено {{ getRightDate(ach.createdAt) }} в
                        {{ getRightTime(ach.createdAt) }}</span
                      >
                    </div>
                  </div>
                </template>
              </div>

              <!-- Опыт -->
              <!-- <div class="HLBlock" v-for="n in 1">
                <div class="XPLineWrapper">
                  <div class="XPLine">
                    <div class="XPProgressLine" style="width: 50%;"></div>
                  </div>
                </div>
                <div class="Text">
                  <span class="HBlockText">Получено достижение</span>
                  <div class="BottomText">
                    <span class="AchText" lang="ru"
                      >Вы зарегистрировались на платформе Октагон</span
                    >
                    <span class="AchDate" lang="ru"
                      >Получено 23 января в 18:00</span
                    >
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </OneColumn>
</template>

<script>
import { findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";
import { refresh } from "@/api/auth.js";

import OneColumn from "@/components/ProfileTemplates/OneColumn.vue";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
import Octocoins from "@/components/CustomElements/Octocoins.vue";

import { findByUserAchievements } from "@/api/achievements.js";
import {
  getCoinsValueForUser,
  getCoinsHistoryForUser,
} from "@/api/user_coins.js";

import { getCoinsText } from "@/middlewares/getCoinsText.js";

export default {
  data() {
    return {
      achievementsList: [],
      xpList: [],
      coinsList: [],

      coins_value: 0,
    };
  },
  name: "TemplateTest",
  components: {
    OneColumn,
    CircleQuestionIcon,
    ButtonGradientBorder,
    Octocoins,
  },
  computed: {
    getNullBlocksCounter() {
      return this.achievementsList.length % 7;
    },

    getCoinsText() {
      return getCoinsText(this.coins_value);
    },

    getFirstFiveAchievements() {
      return this.achievementsList.slice(0, 5);
    },

    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    userLevels() {
      return this.user.user_cells.length > 0
        ? this.user.user_cells.map((cell) => cell.level_id)
        : [];
    },

    getHistoryList() {
      let list = [];
      list = list.concat(this.achievementsList);
      list = list.concat(this.xpList);
      list = list.concat(this.coinsList);

      list = list.sort((a, b) => {
        let DateA = new Date(
          a.element_type === "ach" ? a.createdDate : a.createdAt
        );
        let DateB = new Date(
          b.element_type === "ach" ? b.createdDate : b.createdAt
        );
        return DateB - DateA;
      });

      return list;
    },

    getFormattedCoinsValue() {
      return new Intl.NumberFormat("ru-RU").format(this.coins_value);
    },
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  methods: {
    getFormattedNumber(nums) {
      return new Intl.NumberFormat("ru-RU").format(nums);
    },

    ...mapMutations("auth", ["SET_USER"]),

    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },

    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    getRightTime(createdDate) {
      let time = createdDate.split("T")[1];
      return time.substr(0, time.indexOf(".") - 3);
    },
    getRightDate(createdDate) {
      let date = createdDate.split("T")[0];

      // Разбиваем входную дату на отдельные компоненты
      const [year, month, day] = date.split("-");

      // Создаем объект Date из разобранных компонентов
      const _date = new Date(year, month - 1, day);

      // Определяем названия месяцев
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

      const formattedDate = `${parseInt(day)} ${months[_date.getMonth()]}`;

      return formattedDate;
    },

    getAchIMG(ach_url) {
      return `
      background: url("${process.env.VUE_APP_BACK}/${ach_url
        .replaceAll("\\", "/")
        .replaceAll("public", "")}");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      `;
    },
  },
  async created() {
    let _user_coins_resp = await getCoinsValueForUser(this?.user?.id);
    this.coins_value = _user_coins_resp.data;

    let _user_achievements_resp = await findByUserAchievements(this?.user?.id);
    this.achievementsList = _user_achievements_resp.data.map((x) => {
      return { ...x, element_type: "ach" };
    });

    let _user_coins_history_resp = await getCoinsHistoryForUser(this?.user?.id);
    this.coinsList = _user_coins_history_resp.data.map((x) => {
      return { ...x, element_type: "history" };
    });
  },
};
</script>

<style scoped>
.Page-Achievements-Header {
  position: relative;
  display: block;

  margin-top: 30px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}

.OctocoinsText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  color: #6fcf97;
}

.XPLine {
  position: relative;
  display: block;

  margin: auto;

  width: 100%;
  height: 10px;

  background-color: rgb(41, 37, 88);
  border-radius: 12px;
}
.XPProgressLine {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 10%;
  height: 10px;

  background-color: rgb(111, 207, 151);
  border-radius: 12px;
}

.ArrowBack {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
}
.ButtonBack {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;

  width: fit-content;
  padding: 12px 24px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}

.ButtonBack::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ButtonBack > .centerText {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 3.5px;
}
.ButtonBack > .centerText > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ButtonBack > span {
  position: relative;
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  color: #ffffff;
}

.AchDate {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  /* text */
  color: #d3caffb2;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0%;
  text-align: left;

  text-transform: uppercase;
}
.AchText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  /* text */
  color: #d3caffb2;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: 0%;
  text-align: left;
}

.AchIMG {
  position: relative;
  display: block;

  margin: auto;

  width: auto;
  height: 50px;
  aspect-ratio: 1/1;

  border-radius: 5px;
  background: linear-gradient(
    221.15deg,
    rgb(197, 72, 171) -3.396%,
    rgb(99, 91, 255) 101.075%
  );
}
.XPLineWrapper {
  position: relative;
  display: flex;

  width: auto;
  height: 50px;
  aspect-ratio: 1/1;
}

.HLBlock > * {
  margin-top: auto;
  margin-bottom: auto;
}
.HLBlock > .Text {
  position: relative;
  display: flex;

  width: 100%;

  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.HLBlock {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  width: 100%;
  height: fit-content;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(32, 30, 72, 0.7);
}

.BottomText {
  position: relative;
  display: flex;

  width: 100%;

  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.HistoryList {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: 100%;
  height: 100%;

  flex-shrink: 1;
  flex-grow: 0;

  overflow-y: hidden;
}

.HLContent {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  overflow-y: auto;
}

.MyAchList {
  position: relative;
  display: grid;

  grid-template-columns: repeat(7, 40px);
  gap: 10px;

  width: fit-content;
  height: fit-content;
}

.MyAchList > .AchBlock {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 5px;
  background: rgb(46, 43, 107);
}

.GradientText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  /* [object Object] */
  background: linear-gradient(45deg, rgb(245, 100, 232), rgb(98, 219, 246));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0%;
  text-align: left;
}

.ContentWrapper {
  position: relative;
  display: flex;

  width: 100%;
  height: 100%;

  overflow: hidden;
}
.Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: 100%;
  height: auto;

  overflow-y: auto;
}

.ShortInfo {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;

  width: fit-content;
  height: fit-content;
}
.ShortInfo > div {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: rgba(32, 30, 72, 0.7);

  border-radius: var(--o-s-global-border-radius);
}
.ShortInfo > div > .Head {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  width: fit-content;
  height: fit-content;
}
.ShortInfo > div > .Head > * {
  margin-top: auto;
  margin-bottom: auto;
}
.HeadText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: rgb(232, 231, 236);
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0%;
  text-align: left;
}

.HBlockText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  /* h3 */
  color: rgb(232, 231, 236);
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .AchText,
  .AchDate {
    hyphens: auto;
  }

  .GradientText {
    font-size: 16px;
    line-height: 120%;
  }

  .HeadText {
    font-size: 18px;
    line-height: 120%;
  }

  .Content {
    overflow-x: hidden;
  }

  .ShortInfo {
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
  }
  .ShortInfo > * {
    width: 100%;
  }

  .HistoryList {
    gap: 15px;
    height: fit-content;
    overflow-y: auto;
  }
  .HLContent {
    overflow-y: auto;
  }

  .ContentWrapper {
    height: auto;
    overflow: auto;
    padding-bottom: 100px;
  }

  .BottomText {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }

  .HLBlock > .AchIMG {
    margin-top: 5px;
  }

  .MyAchievements {
    overflow-x: auto;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .ShortInfo {
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
  }
  .ShortInfo > * {
    width: 100%;
  }

  .HistoryList {
    gap: 15px;
    height: fit-content;
    overflow-y: auto;
  }
  .HLContent {
    overflow-y: auto;
  }

  .ContentWrapper {
    height: auto;
    overflow: auto;
    padding-bottom: 100px;
  }

  .BottomText {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }

  .HLBlock > .AchIMG {
    margin-top: 5px;
  }

  .MyAchievements {
    overflow-x: auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ShortInfo {
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
  }
  .ShortInfo > * {
    width: 100%;
  }

  .HistoryList {
    gap: 15px;
    height: fit-content;
    overflow-y: auto;
  }
  .HLContent {
    overflow-y: auto;
  }

  .ContentWrapper {
    height: auto;
    overflow: auto;
    padding-bottom: 100px;
  }
}
</style>
